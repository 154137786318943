import Vue from 'vue';
import Vuex from 'vuex';
import attributes from './attributes';
import attributeOptions from './attributeOptions';
import images from './images';
import menus from './menus';
import templates from './templates';
import toasts from './toasts';
import products from './products';
import tags from './tags';
import orders from './orders';
import articles from './articles';
import users from './users';
import analytics from './analytics';
import settings from './settings';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    attributes,
    attributeOptions,
    images,
    menus,
    templates,
    toasts,
    products,
    tags,
    orders,
    articles,
    users,
    analytics,
    settings,
  },
});
