export function convertModelsInResponse(response, map) {
  if (Array.isArray(response.data.data)) {
    return {
      ...response,
      data: {
        ...response.data,
        data: [
          ...response.data.data.map(map)
        ],
      },
    };
  }

  return {
    ...response,
    data: {
      ...response.data,
      data: map(response.data.data),
    },
  };
}
