import {CREATE_IMAGE, DELETE_IMAGE, GET_IMAGE_INFO, GET_IMAGE_LIST} from './consts';
import axios from '@/plugins/axios';
import Image from '@/models/Image';
import {convertModelsInResponse} from '@/utils/convertModelsInResponse';

export default {
  [GET_IMAGE_LIST](context, query = {}) {
    return axios({
      method: 'get',
      url: '/images',
      params: query,
    })
      .then((res) => {
        return convertModelsInResponse(res, (item) => new Image(item));
      });
  },
  [GET_IMAGE_INFO](context, id) {
    return axios({
      method: 'get',
      url: `/images/${id}`,
    })
      .then((res) => {
        return convertModelsInResponse(res, (item) => new Image(item));
      });
  },
  [CREATE_IMAGE](context, options) {
    return axios({
      method: 'post',
      url: '/images',
      data: {file: options.file},
      onUploadProgress: (progressEvent) => {
        if (Object.hasOwnProperty.call(options, 'onUploadProgress')) {
          const percent = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          options.onUploadProgress(percent);
        }
      },
    })
      .then((res) => {
        return convertModelsInResponse(res, (item) => new Image(item));
      });
  },
  [DELETE_IMAGE](context, id) {
    return axios({
      method: 'delete',
      url: `/images/${id}`,
    });
  }
};
