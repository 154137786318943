import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'index',
    redirect: '/analytics',
  },
  {
    path: '/analytics',
    name: 'analytics.index',
    component: () => import(/* webpackChunkName: "admin-analytics" */ '../views/analytics/index.vue')
  },
  {
    path: '/attributes',
    name: 'attributes.index',
    component: () => import(/* webpackChunkName: "admin-attributes" */ '../views/attributes/index.vue')
  },
  {
    path: '/attributes/create',
    name: 'attributes.create',
    component: () => import(/* webpackChunkName: "admin-attributes" */ '../views/attributes/create.vue')
  },
  {
    path: '/attributes/:attribute/edit',
    name: 'attributes.edit',
    component: () => import(/* webpackChunkName: "admin-attributes" */ '../views/attributes/_attribute/edit.vue')
  },
  {
    path: '/images',
    name: 'images.index',
    component: () => import(/* webpackChunkName: "admin-images" */ '../views/images/index.vue')
  },
  {
    path: '/menus',
    name: 'menu.index',
    component: () => import(/* webpackChunkName: "admin-menu" */ '../views/menus/index.vue')
  },
  {
    path: '/menus/create',
    name: 'menu.create',
    component: () => import(/* webpackChunkName: "admin-menu" */ '../views/menus/create.vue')
  },
  {
    path: '/menus/:menu/edit',
    name: 'menu.edit',
    component: () => import(/* webpackChunkName: "admin-menu" */ '../views/menus/_menu/edit.vue')
  },
  {
    path: '/templates',
    name: 'template.index',
    component: () => import(/* webpackChunkName: "admin-templates" */ '../views/templates/index.vue')
  },
  {
    path: '/templates/create',
    name: 'template.create',
    component: () => import(/* webpackChunkName: "admin-templates" */ '../views/templates/create.vue')
  },
  {
    path: '/templates/:template/edit',
    name: 'template.edit',
    component: () => import(/* webpackChunkName: "admin-templates" */ '../views/templates/_template/edit.vue')
  },
  {
    path: '/products',
    name: 'product.index',
    component: () => import(/* webpackChunkName: "admin-products" */ '../views/products/index.vue')
  },
  {
    path: '/products/create',
    name: 'product.create',
    component: () => import(/* webpackChunkName: "admin-products" */ '../views/products/create.vue')
  },
  {
    path: '/products/:product/edit',
    name: 'product.edit',
    component: () => import(/* webpackChunkName: "admin-products" */ '../views/products/_product/edit.vue')
  },
  {
    path: '/orders',
    name: 'order.index',
    component: () => import(/* webpackChunkName: "admin-orders" */ '../views/orders/index.vue')
  },
  {
    path: '/orders/:order',
    name: 'order.show',
    component: () => import(/* webpackChunkName: "admin-orders" */ '../views/orders/_order/show.vue')
  },
  {
    path: '/articles',
    name: 'article.index',
    component: () => import(/* webpackChunkName: "admin-articles" */ '../views/articles/index.vue')
  },
  {
    path: '/articles/create',
    name: 'article.create',
    component: () => import(/* webpackChunkName: "admin-articles" */ '../views/articles/create.vue')
  },
  {
    path: '/articles/:article/edit',
    name: 'article.edit',
    component: () => import(/* webpackChunkName: "admin-articles" */ '../views/articles/_article/edit.vue')
  },
  {
    path: '/users',
    name: 'user.index',
    component: () => import(/* webpackChunkName: "admin-users" */ '../views/users/index.vue')
  },
  {
    path: '/users/create',
    name: 'user.create',
    component: () => import(/* webpackChunkName: "admin-users" */ '../views/users/create.vue')
  },
  {
    path: '/users/:user/edit',
    name: 'user.edit',
    component: () => import(/* webpackChunkName: "admin-users" */ '../views/users/_user/edit.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "admin-login" */ '../views/login.vue')
  },
  {
    path: '/settings',
    name: 'settings.edit',
    component: () => import(/* webpackChunkName: "admin-settings" */ '../views/settings/index.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "admin" */ '../views/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
