import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: false,
  state() {
    return {
      toasts: [],
    };
  },
  getters,
  actions,
  mutations,
};
