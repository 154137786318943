import {GET_SETTINGS, UPDATE_SETTINGS} from './consts';
import axios from '@/plugins/axios';

export default {
  [GET_SETTINGS]() {
    return axios({
      method: 'get',
      url: '/settings',
    });
  },
  [UPDATE_SETTINGS](context, settings) {
    return axios({
      method: 'put',
      url: '/settings',
      data: settings.toRequestObject(),
    });
  },
};
