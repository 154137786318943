import {CREATE_ARTICLE, DELETE_ARTICLE, GET_ARTICLE, GET_ARTICLE_LIST, UPDATE_ARTICLE} from './consts';
import axios from '@/plugins/axios';
import Article from '@/models/Article';

export default {
  [GET_ARTICLE_LIST](context, query = {}) {
    return axios({
      method: 'get',
      url: '/articles',
      params: query,
    });
  },
  [CREATE_ARTICLE](context, article) {
    return axios({
      method: 'post',
      url: '/articles',
      data: article instanceof Article ? article.toRequestObject() : article,
    });
  },
  [GET_ARTICLE](context, id) {
    return axios({
      method: 'get',
      url: `/articles/${id}`,
    });
  },
  [UPDATE_ARTICLE](context, article) {
    return axios({
      method: 'put',
      url: `/articles/${article.id}`,
      data: article instanceof Article ? article.toRequestObject() : article,
    });
  },
  [DELETE_ARTICLE](context, id) {
    return axios({
      method: 'delete',
      url: `/articles/${id}`,
    });
  },
};
