import {CREATE_USER, DELETE_USER, GET_CURRENT_USER, GET_USER, GET_USER_LIST, UPDATE_USER} from './consts';
import axios from '@/plugins/axios';

export default {
  [GET_USER_LIST](context, query = {}) {
    return axios({
      method: 'get',
      url: '/users',
      params: query,
    });
  },
  [GET_USER](context, id) {
    return axios({
      method: 'get',
      url: `/users/${id}`,
    });
  },
  [GET_CURRENT_USER]() {
    return axios({
      method: 'get',
      url: '/users/me',
    });
  },
  [CREATE_USER](context, user) {
    return axios({
      method: 'post',
      url: `/users`,
      data: user.toRequestObject(),
    });
  },
  [UPDATE_USER](context, user) {
    return axios({
      method: 'put',
      url: `/users/${user.id}`,
      data: user.toRequestObject(),
    });
  },
  [DELETE_USER](context, id) {
    return axios({
      method: 'delete',
      url: `/users/${id}`,
    });
  },
};
