import DateTimeFormatter from "@lapaliv/datetime-formatter";

export function refreshTokens
({
   accessToken,
   refreshToken,
   accessExpiresIn,
   refreshExpiresIn,
 }) {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('accessExpiresIn', DateTimeFormatter.now().addSeconds(accessExpiresIn).toDateTimeString());
  localStorage.setItem('refreshExpiresIn', DateTimeFormatter.now().addSeconds(refreshExpiresIn).toDateTimeString());
}
