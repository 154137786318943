import {
  EXPORT_ORDERS,
  GET_NUMBER_OF_NEW_ORDERS,
  GET_ORDER_INFO,
  GET_ORDER_LIST,
  SET_NUMBER_OF_NEW_ORDERS,
  UPDATE_ORDER
} from './consts';
import axios from '@/plugins/axios';

export default {
  [GET_ORDER_LIST](context, query = {}) {
    return axios({
      method: 'get',
      url: '/orders',
      params: query,
    });
  },
  [GET_ORDER_INFO](context, id) {
    return axios({
      method: 'get',
      url: `/orders/${id}`,
    });
  },
  [GET_NUMBER_OF_NEW_ORDERS](context) {
    return axios({
      method: 'get',
      url: '/orders/count-new',
    })
      .then((res) => {
        context.commit(SET_NUMBER_OF_NEW_ORDERS, res.data.data);

        return res;
      });
  },
  [UPDATE_ORDER](context, order) {
    return axios({
      method: 'put',
      url: `/orders/${order.id}`,
      data: order.toObject(),
    });
  },
  [EXPORT_ORDERS]() {
    return axios({
      method: 'get',
      url: `/orders/export`,
    });
  },
};
