import {Model} from './Model';

export default class Template extends Model {
  static TEMPLATE_PRODUCT = 'product';
  static TEMPLATE_MENU = 'menu';
  static TEMPLATE_ARTICLE = 'article';

  constructor(props) {
    super(props, {
      id: null,
      type: null,
      name: null,
      content: null,
      createdAt: null,
      updatedAt: null,
    }, {
      createdAt: null,
      updatedAt: null,
    });
  }
}
