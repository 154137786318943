import {SET_ATTRIBUTE_LIST} from "@/store/attributes/consts";
import Attribute from "@/models/Attribute";
import {sortBy} from "lodash";

export default {
  [SET_ATTRIBUTE_LIST](state, list) {
    state.attributes = Attribute.collection(
      sortBy(list, {required: 'desc'})
    )
  }
}