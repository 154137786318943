import {extend, localize, ValidationObserver, ValidationProvider,} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import Vue from 'vue';

const validator = require('validator');

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend('url', (value, params) => {
  return validator.isURL(value, params[0]);
});

extend('boolean', (value) => {
  return [true, false].includes(value);
});

extend('min_array', (value, params) => {
  if (Array.isArray(value) && value.length >= params[0]) {
    return true;
  }

  return `Необходимо выбрать минимум ${params[0]} шт`;
});

extend('max_array', (value, params) => {
  if (Array.isArray(value) && value.length <= params[0]) {
    return true;
  }

  return `Нельзя выбрать больше ${params[0]} шт`;
});

localize('en', {
  code: 'en',
  messages: {
    alpha: 'The field may only contain alphabetic characters',
    alpha_num: 'The field may only contain alpha-numeric characters',
    alpha_dash:
      'The field may contain alpha-numeric characters as well as dashes and underscores',
    alpha_spaces:
      'The field may only contain alphabetic characters as well as spaces',
    between: 'The field must be between {min} and {max}',
    confirmed: 'The field confirmation does not match',
    digits: 'The field must be numeric and exactly contain {length} digits',
    dimensions: 'The field must be {width} pixels by {height} pixels',
    email: 'The field must be a valid email',
    excluded: 'The field is not a valid value',
    ext: 'The field is not a valid file',
    image: 'The field must be an image',
    integer: 'Значение должно быть целым числом',
    length: 'The field must be {length} long',
    max_value: 'The field must be {max} or less',
    max: 'The value may not be greater than {length} characters',
    mimes: 'The field must have a valid file type',
    min_value: 'The field must be {min} or more',
    min: 'The field must be at least {length} characters',
    numeric: 'The field may only contain numeric characters',
    oneOf: 'The field is not a valid value',
    regex: 'The field format is invalid',
    required_if: 'The field is required',
    required: 'Поле обязательно для заполнения',
    size: 'The field size must be less than {size}KB',
    boolean: 'The value is invalid',
    url: 'The format is invalid',
  },
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
