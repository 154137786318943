import {Model} from './Model';

export default class Attribute extends Model {
  static STATUS_PUBLISHED = 'published';
  static STATUS_UNPUBLISHED = 'unpublished';

  constructor(props = {}) {
    super(
      props,
      {
        id: null,
        name: null,
        type: null,
        required: null,
        min: null,
        max: null,
        isFloat: null,
        decimalPlaces: null,
        yes: null,
        no: null,
        height: null,
        multiple: null,
        default: null,
        status: null,
      }
    );

    this.type ??= ATTRIBUTE_TYPE_NUMBER;
    this.required ??= true;
    this.isFloat ??= false;
    this.decimalPlaces ??= 2;
    this.yes ??= 'Да';
    this.no ??= 'Нет';
    this.height ??= 1;
    this.multiple ??= false;
    this.status ??= Attribute.STATUS_PUBLISHED;
  }

  static get types() {
    return {
      [ATTRIBUTE_TYPE_LIST]: 'Список',
      [ATTRIBUTE_TYPE_BOOL]: 'Да/Нет',
      [ATTRIBUTE_TYPE_TEXT]: 'Текст',
      [ATTRIBUTE_TYPE_NUMBER]: 'Число',
      [ATTRIBUTE_TYPE_COLOR]: 'Цвет',
    };
  }

  get typeTitle() {
    return Attribute.types[this.type] ?? null;
  }

  get typeIsNumber() {
    return this.type === ATTRIBUTE_TYPE_NUMBER;
  }

  get typeIsBool() {
    return this.type === ATTRIBUTE_TYPE_BOOL;
  }

  get typeIsText() {
    return this.type === ATTRIBUTE_TYPE_TEXT;
  }

  get typeIsList() {
    return this.type === ATTRIBUTE_TYPE_LIST;
  }

  get typeIsColor() {
    return this.type === ATTRIBUTE_TYPE_COLOR;
  }

  static make(props) {
    return new Attribute(props);
  }
}

export const ATTRIBUTE_TYPE_LIST = 'list';
export const ATTRIBUTE_TYPE_BOOL = 'bool';
export const ATTRIBUTE_TYPE_TEXT = 'text';
export const ATTRIBUTE_TYPE_NUMBER = 'number';
export const ATTRIBUTE_TYPE_COLOR = 'color';
