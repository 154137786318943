import axios from '@/plugins/axios';
import {
  CREATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  GET_ATTRIBUTE,
  GET_ATTRIBUTE_LIST,
  LOAD_ATTRIBUTE_LIST,
  SET_ATTRIBUTE_LIST,
  UPDATE_ATTRIBUTE
} from './consts';
import Attribute from "@/models/Attribute";

export default {
  [GET_ATTRIBUTE_LIST](context, query = {}) {
    return axios({
      method: 'GET',
      url: '/attributes',
      params: query,
    });
  },
  [LOAD_ATTRIBUTE_LIST](context) {
    if (context.state.attributes.length > 0 && context.state.isAttributeListLoading === false) {
      return;
    }

    context.state.isAttributeListLoading = true;

    context.dispatch(GET_ATTRIBUTE_LIST, {
      offset: context.state.attributes.length,
      limit: 100,
    })
      .then((res) => {
        context.commit(SET_ATTRIBUTE_LIST, [
          ...context.state.attributes,
          ...res.data.data,
        ])

        if (res.data.meta.total > context.state.attributes.length) {
          context.dispatch(LOAD_ATTRIBUTE_LIST)
        } else {
          context.state.isAttributeListLoading = false;
        }
      })
  },
  [CREATE_ATTRIBUTE](context, attribute) {
    return axios({
      method: 'POST',
      url: '/attributes',
      data: attribute.toObject(),
    })
      .then((res) => {
        if (context.state.attributes.length > 0) {
          context.commit(SET_ATTRIBUTE_LIST, [
            ...context.state.attributes,
            res.data.data
          ])
        }

        return res
      });
  },
  [UPDATE_ATTRIBUTE](context, data) {
    return axios({
      method: 'PUT',
      url: `/attributes/${data.id}`,
      data: data,
    })
      .then((res) => {
        context.state.attributes = context.state.attributes.map((attribute) => {
          if (attribute.id === data.id) {
            return new Attribute(res.data.data)
          }

          return attribute
        })

        return res
      });
  },
  [GET_ATTRIBUTE](context, id) {
    return axios({
      method: 'GET',
      url: `/attributes/${id}`,
    });
  },
  [DELETE_ATTRIBUTE](context, id) {
    return axios({
      method: 'DELETE',
      url: `/attributes/${id}`,
    })
      .then((res) => {
        if (res.data.data) {
          context.state.attributes = context.state.attributes.filter((attribute) => {
            return attribute.id !== id
          })
        }

        return res
      });
  },
};
