import DateTimeFormatter from '@lapaliv/datetime-formatter';

export class Model {
  static DEFAULT_DATE_FORMAT = 'Y-m-d H:i:s';

  /**
   *
   * @param props
   * @param {object} fillable
   * @param {object} dates
   */
  constructor(props, fillable, dates = {}) {
    for (const key in props) {
      if (Object.hasOwnProperty.call(fillable, key) === false
        && Object.hasOwnProperty.call(dates, key) === false
      ) {
        continue;
      }

      const value = props[key];

      if (Object.hasOwnProperty.call(dates, key)) {
        if (value) {
          this[key] = value instanceof DateTimeFormatter
            ? value
            : DateTimeFormatter.createFromFormat(dates[key] ?? Model.DEFAULT_DATE_FORMAT, value);
        } else {
          this[key] = null;
        }
      } else if (fillable[key] !== null) {
        if (Array.isArray(value)) {
          this[key] = fillable[key].collection(value);
        } else {
          this[key] = value ? new fillable[key](value) : null;
        }
      } else {
        this[key] = value ?? null;
      }
    }

    this._fillable = fillable;
  }

  /**
   * @param {object[]} items
   * @returns {*[]}
   */
  static collection(items) {
    let result = [];

    for (const item of items) {
      if (item instanceof Model) {
        result.push(item);
      } else {
        result.push(this.make(item));
      }
    }

    return result;
  }

  static make() {
    throw new Error('Method make in the model is not implemented');
  }

  toObject() {
    let result = {};

    for (const key in this._fillable) {
      const value = this[key];

      if (value !== undefined) {
        result[key] = value;
      }
    }

    return result;
  }

  toRequestObject() {
    const object = this.toObject();
    let result = {};

    for (const key in object) {
      const value = object[key];

      if (value instanceof Model) {
        result[key] = value.id;
      } else if (value !== null && typeof value === 'object' && Object.hasOwnProperty.call(value, 'id')) {
        result[key] = value.id;
      } else if (Array.isArray(value)) {
        result[key] = [];

        for (const item of value) {
          if (value instanceof Model) {
            result[key].push(item.id);
          } else if (typeof value === 'object' && value !== null && Object.hasOwnProperty.call(item, 'id')) {
            result[key].push(item.id);
          } else {
            result[key].push(item);
          }
        }
      } else if (!['createdAt', 'updatedAt'].includes(key)) {
        result[key] = value;
      }
    }

    return result;
  }
}
