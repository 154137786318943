import {CREATE_TEMPLATE, DELETE_TEMPLATE, GET_TEMPLATE_INFO, GET_TEMPLATE_LIST, UPDATE_TEMPLATE} from './consts';
import axios from '@/plugins/axios';

export default {
  [GET_TEMPLATE_LIST](context, query = {}) {
    return axios({
      method: 'get',
      url: '/templates',
      params: query,
    });
  },
  [CREATE_TEMPLATE](context, data = {}) {
    return axios({
      method: 'post',
      url: '/templates',
      data,
    });
  },
  [GET_TEMPLATE_INFO](context, id) {
    return axios({
      method: 'get',
      url: `/templates/${id}`,
    });
  },
  [UPDATE_TEMPLATE](context, page) {
    return axios({
      method: 'put',
      url: `/templates/${page.id}`,
      data: page,
    });
  },
  [DELETE_TEMPLATE](context, id) {
    return axios({
      method: 'delete',
      url: `/templates/${id}`,
    });
  },
};
