import {CREATE_MENU, DELETE_MENU, GET_MENU_INFO, GET_MENU_LIST, UPDATE_MENU} from './consts';
import axios from '@/plugins/axios';

export default {
  [GET_MENU_LIST](context, query = {}) {
    return axios({
      method: 'get',
      url: '/menus',
      params: query,
    });
  },
  [GET_MENU_INFO](context, id) {
    return axios({
      method: 'get',
      url: `/menus/${id}`,
    });
  },
  [CREATE_MENU](context, menu) {
    return axios({
      method: 'post',
      url: '/menus',
      data: menu.toObject(),
    });
  },
  [UPDATE_MENU](context, menu) {
    return axios({
      method: 'put',
      url: `/menus/${menu.id}`,
      data: menu.toObject(),
    });
  },
  [DELETE_MENU](context, id) {
    return axios({
      method: 'delete',
      url: `/menus/${id}`,
    });
  },
};
