import {Model} from './Model';

export default class Tag extends Model {
  constructor(props) {
    super(props, {
      id: null,
      name: null,
    });
  }

  static make(props) {
    return new Tag(props);
  }
}
