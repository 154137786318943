import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  EXPORT_PRODUCTS,
  GET_PRODUCT_INFO,
  GET_PRODUCT_LIST,
  IMPORT_PRODUCTS,
  UPDATE_PRODUCT
} from './conts';
import axios from '@/plugins/axios';

export default {
  [GET_PRODUCT_LIST](context, query = {}) {
    return axios({
      method: 'get',
      url: '/products',
      params: query,
    });
  },
  [GET_PRODUCT_INFO](context, id) {
    return axios({
      method: 'get',
      url: `/products/${id}`,
    });
  },
  [CREATE_PRODUCT](context, product) {
    return axios({
      method: 'post',
      url: `/products`,
      data: product.toRequestObject(),
    });
  },
  [UPDATE_PRODUCT](context, product) {
    return axios({
      method: 'put',
      url: `/products/${product.id}`,
      data: product.toRequestObject(),
    });
  },
  [DELETE_PRODUCT](context, id) {
    return axios({
      method: 'delete',
      url: `/products/${id}`,
    });
  },
  [EXPORT_PRODUCTS]() {
    return axios({
      method: 'get',
      url: `/products/export`,
    });
  },
  [IMPORT_PRODUCTS](context, file) {
    return axios({
      method: 'post',
      url: `/products/import`,
      data: {file},
    });
  },
};
