import {Model} from './Model';
import Image from './Image';
import Tag from './Tag';
import Template from './Template';

export default class Article extends Model {
  static STATUS_PUBLISHED = 'published';
  static STATUS_UNPUBLISHED = 'unpublished';

  constructor(props) {
    super(props, {
      id: null,
      name: null,
      slug: null,
      annotation: null,
      content: null,
      status: null,
      createdAt: null,
      updatedAt: null,
      image: Image,
      tags: Tag,
      template: Template,
    }, {
      createdAt: null,
      updatedAt: null,
    });

    this.status ??= Article.STATUS_PUBLISHED;
    this.tags ??= [];
  }

  get humanStatus() {
    switch (this.status) {
      case Article.STATUS_PUBLISHED:
        return 'Опубликована';
      case Article.STATUS_UNPUBLISHED:
        return 'Не опубликована';
      default:
        return 'Unknown';
    }
  }

  static make(props) {
    return new Article(props);
  }
}
