import {CREATE_TAG, GET_TAG_LIST} from './consts';
import axios from '@/plugins/axios';

export default {
  [GET_TAG_LIST](context, query = {}) {
    return axios({
      method: 'GET',
      url: '/tags',
      params: query,
    });
  },
  [CREATE_TAG](context, data) {
    return axios({
      method: 'POST',
      url: '/tags',
      data,
    });
  },
};
