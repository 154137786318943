import {Model} from './Model';

export default class Image extends Model {
  constructor(props = {}) {
    super(
      props,
      {
        id: null,
        parentId: null,
        url: null,
        width: null,
        height: null,
        x: null,
        y: null,
        children: Image,
        base64: null,
        progress: null,
      },
      {
        createdAt: null,
        updatedAt: null,
      }
    );
  }

  static make(props = {}) {
    return new Image(props);
  }
}

