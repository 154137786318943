import axios from 'axios';
import DateTimeFormatter from '@lapaliv/datetime-formatter';
import {refreshTokens} from "@/utils/refreshTokens";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  //timeout: 1000,
  headers: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: false,
});

instance.interceptors.request.use(
  async function (config) {
    const params = {};

    for (const key in config.params ?? {}) {
      if ([null, undefined, ''].includes(config.params[key]) === false) {
        params[key] = config.params[key];
      }
    }

    // Ищем файлы
    let hasFiles = false;

    if (typeof config.data === 'object' && config.data !== null) {
      for (const key in config.data ?? {}) {
        if (config.data[key] instanceof File) {
          hasFiles = true;
          break;
        }
      }
    }


    if (hasFiles) {
      const form = new FormData();
      for (const key in config.data) {
        form.append(key, config.data[key]);
      }

      config.data = form;
    }

    if (!config.url.match(/^\/?auth\/(login|refresh)/)) {
      let accessToken = localStorage.getItem('accessToken');
      const accessExpiresIn = localStorage.getItem('accessExpiresIn');

      if (accessToken) {
        if (DateTimeFormatter.parse(accessExpiresIn).isPast()) {
          try {
            await refreshToken();

            accessToken = localStorage.getItem('accessToken');
          } catch (error) {
            window.location.href = '/login';
          }
        }

        config.headers['X-TOKEN'] ??= accessToken;
      }
    }

    config.params = params;

    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

instance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  async function (error) {
    if (error.response?.status === 401 && error.config?.url && !error.config.url.match(/^\/?auth\/(login|refresh)/)) {
      try {
        await refreshToken();

        try {
          return await instance.request({
            ...error.config,
            headers: {
              ...error.config.headers,
              'X-TOKEN': localStorage.getItem('access_token'),
            }
          });
        } catch (responseError) {
          return Promise.reject(responseError);
        }
      } catch (error) {
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

let refreshPromise = null;

async function refreshToken() {
  if (refreshPromise) {
    return refreshPromise;
  }

  refreshPromise = new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refreshToken');
    const refreshExpiresIn = localStorage.getItem('refreshExpiresIn');

    if (!refreshToken || !refreshExpiresIn || DateTimeFormatter.parse(refreshExpiresIn).isPast()) {
      return reject();
    }

    instance.put('/auth/refresh', {}, {
      headers: {
        'X-TOKEN': refreshToken,
      }
    })
      .then((res) => {
        refreshTokens(res.data.data)

        resolve();
      })
      .catch((error) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessExpiresIn');
        localStorage.removeItem('refreshExpiresIn');

        reject(error);
      })
      .finally(() => {
        refreshPromise = null;
      });
  });

  return refreshPromise;
}

export default instance;
