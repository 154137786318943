import {filter} from 'lodash';
import {ADD_CUSTOM_TOAST, ADD_ERROR_TOAST, ADD_INFO_TOAST, ADD_WARNING_TOAST, REMOVE_TOAST,} from './consts';

import {v4 as uuidv4} from 'uuid';

export default {
  [ADD_CUSTOM_TOAST](
    {state, dispatch},
    {
      title,
      message,
      html,
      duration,
      type,
      icon,
      iconClass,
      itemClass,
      errors,
      onRemove,
      actions, // [{title: string, variant: String, onClick: Function}]
    },
  ) {
    const uuid = uuidv4();

    if (
      duration === undefined ||
      duration < 0 ||
      typeof duration !== 'number'
    ) {
      duration = 5000;
    }

    state.toasts = [
      ...state.toasts,
      {
        uuid,
        title,
        message,
        html,
        type,
        icon,
        iconClass,
        itemClass,
        errors,
        onRemove,
        actions: Array.isArray(actions) ? actions : [],
      },
    ];

    if (duration > 0) {
      setTimeout(() => {
        dispatch(REMOVE_TOAST, uuid);
      }, duration);
    }
  },
  [ADD_INFO_TOAST]({dispatch}, data) {
    dispatch(ADD_CUSTOM_TOAST, {
      icon: 'check-circle',
      iconClass: 'text-info',
      itemClass: 'toasts__item--info',
      ...(typeof data === 'string' ? {title: 'Успешно', message: data} : data),
      type: 'info',
    });
  },
  [ADD_ERROR_TOAST]({dispatch}, data) {
    dispatch(ADD_CUSTOM_TOAST, {
      icon: 'exclamation-circle',
      iconClass: 'text-danger',
      itemClass: 'toasts__item--danger',
      ...(typeof data === 'string' ? {title: 'Ошибка', message: data} : data),
      type: 'error',
    });
  },
  [ADD_WARNING_TOAST]({dispatch}, data) {
    dispatch(ADD_CUSTOM_TOAST, {
      icon: 'exclamation-circle',
      iconClass: 'text-warning',
      itemClass: 'toasts__item--warning',
      ...(typeof data === 'string'
        ? {title: 'Предупреждение', message: data}
        : data),
      type: 'warning',
    });
  },
  [REMOVE_TOAST]: ({state}, uuid) => {
    const potentialToasts = filter(state.toasts, (item) => item.uuid === uuid);

    if (
      potentialToasts.length > 0 &&
      typeof potentialToasts[0].onRemove === 'function'
    ) {
      potentialToasts[0].onRemove();
    }

    state.toasts = filter(state.toasts, (item) => item.uuid !== uuid);
  },
};
