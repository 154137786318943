import axios from '@/plugins/axios';
import {
  CREATE_ATTRIBUTE_OPTION,
  DELETE_ATTRIBUTE_OPTION,
  GET_ATTRIBUTE_OPTION,
  GET_ATTRIBUTE_OPTION_LIST,
  UPDATE_ATTRIBUTE_OPTION
} from './consts';

export default {
  [GET_ATTRIBUTE_OPTION_LIST](context, query = {}) {
    return axios({
      method: 'GET',
      url: '/attribute-options',
      params: query,
    });
  },
  [CREATE_ATTRIBUTE_OPTION](context, data) {
    return axios({
      method: 'POST',
      url: '/attribute-options',
      data: data,
    });
  },
  [UPDATE_ATTRIBUTE_OPTION](context, data) {
    return axios({
      method: 'PUT',
      url: `/attribute-options/${data.id}`,
      data: data,
    });
  },
  [GET_ATTRIBUTE_OPTION](context, id) {
    return axios({
      method: 'GET',
      url: `/attribute-options/${id}`,
    });
  },
  [DELETE_ATTRIBUTE_OPTION](context, id) {
    return axios({
      method: 'DELETE',
      url: `/attribute-options/${id}`,
    });
  },
};
