import axios from '@/plugins/axios';
import {
  GET_ANALYTICS,
  GET_ANALYTICS_BROWSERS,
  GET_ANALYTICS_GEOGRAPHY_COUNTRIES,
  GET_ANALYTICS_GEOGRAPHY_REGIONS,
  GET_ANALYTICS_INDICES,
  GET_ANALYTICS_OPERATING_SYSTEMS,
  GET_ANALYTICS_PAGES,
  GET_ANALYTICS_REFERRERS,
} from './consts';

export default {
  // [GET_ANALYTICS_GEOGRAPHY](context, query = {}) {
  //   return axios({
  //     method: 'get',
  //     url: '/analytics/geography',
  //     params: query,
  //   })
  // },
  // [GET_ANALYTICS_CUSTOMERS](context, query = {}) {
  //   return axios({
  //     method: 'get',
  //     url: '/analytics/customers',
  //     params: query,
  //   })
  // },
  // [GET_ANALYTICS_DEVICES](context, query = {}) {
  //   return axios({
  //     method: 'get',
  //     url: '/analytics/devices',
  //     params: query,
  //   })
  // },
  // [GET_ANALYTICS_CONTENT](context, query = {}) {
  //   return axios({
  //     method: 'get',
  //     url: '/analytics/content',
  //     params: query,
  //   })
  // },
  // [GET_ANALYTICS_PAGES](context, query = {}) {
  //   return axios({
  //     method: 'get',
  //     url: '/analytics/pages',
  //     params: query,
  //   })
  // },

  [GET_ANALYTICS](context, query) {
    return axios({
      method: 'get',
      url: '/analytics',
      params: query,
    })
  },
  [GET_ANALYTICS_BROWSERS](context, query) {
    return axios({
      method: 'get',
      url: '/analytics-browsers',
      params: query,
    })
  },
  [GET_ANALYTICS_GEOGRAPHY_COUNTRIES](context, query) {
    return axios({
      method: 'get',
      url: '/analytics-geographies/countries',
      params: query,
    })
  },
  [GET_ANALYTICS_GEOGRAPHY_REGIONS](context, query) {
    return axios({
      method: 'get',
      url: '/analytics-geographies/regions',
      params: query,
    })
  },
  [GET_ANALYTICS_INDICES](context, query) {
    return axios({
      method: 'get',
      url: '/analytics-indices',
      params: query,
    })
  },
  [GET_ANALYTICS_OPERATING_SYSTEMS](context, query) {
    return axios({
      method: 'get',
      url: '/analytics-operating-systems',
      params: query,
    })
  },
  [GET_ANALYTICS_PAGES](context, query) {
    return axios({
      method: 'get',
      url: '/analytics-pages',
      params: query,
    })
  },
  [GET_ANALYTICS_REFERRERS](context, query) {
    return axios({
      method: 'get',
      url: '/analytics-referrers',
      params: query,
    })
  },
};
